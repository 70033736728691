<template>
  <vxe-modal
    width="780px"
    :title="'材质上传' + ' ID:' + dataForm.id"
    v-model="showModal"
    :esc-closable="true"
    :lockView="false"
    :mask="false"
  >
    <el-upload
      ref="refUpload"
      action="#"
      list-type="picture-card"
      :file-list="file_img_list"
      :auto-upload="true"
      :http-request="uploadFile"
      :on-remove="handleRemove"
      accept=".jpg,.jpeg,.png"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img
          class="el-upload-list__item-thumbnail"
          v-if="file.url.startsWith('blob')"
          :src="file.url"
          alt=""
        />
        <img class="el-upload-list__item-thumbnail" v-else :src="'/img' + file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="itemImgPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
            <i class="el-icon-download"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <vxe-modal
      title="资料图片"
      width="500px"
      v-model="dialogVisible"
      :esc-closable="true"
      :lock-view="false"
      :mask="false"
      resize
    >
      <el-image style="width: 100%" :src="'/img' + dialogImageUrl" />
    </vxe-modal>
    <div align="center">
      <el-button size="mini" @click="showModal = false">取消</el-button>
    </div>
  </vxe-modal>
</template>

<script>
export default {
  name: 'UploadImg',
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      bigImgVisible: false,
      disabled: false,
      showModal: false,

      file_img_list: [],
      dataForm: {
        id: '',
        img_list: ''
      }
    }
  },

  created() {
    // 根据回显的图片路径通过,逗号拼接的图片路径字符串，来实现图片的回显，则可以将图片路径字符串转化为一个fileList格式的数组才可以。
  },
  methods: {
    uploadFile(params) {
      const file = params.file
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          if (this.beforeUpload(file)) {
            // 根据后台需求数据格式
            const form = new FormData()
            // 文件对象
            form.append('file', file)
            form.append('uid', file.uid)
            form.append('fileType', 'czs')
            form.append('dataForm', JSON.stringify(this.dataForm))
            // 本例子主要要在请求时新增特定属性，所以要用自己方法覆盖默认的action
            // form.append("enctype", 'multipart/form-data');// enctype="multipart/form-data"
            // 项目封装的请求方法，下面做简单介绍
            this.$api({
              method: 'post',
              url: '/admin/upload/img',
              // headers: {"content-type": "multipart/form-data"},
              data: form
            })
              .then((res) => {
                if (res.status === 200 && res.data.state === 'ok') {
                  this.$message.success('上传图片成功!')
                } else {
                  this.$message.error(res.data.msg)
                  // 上传失败，直接移除
                  this.handleRemove(file)
                  return
                }
              })
              .catch(() => {
                // 上传失败，直接移除
                this.handleRemove(file)
              })
          }
        } else {
          this.$message.error('请先填写完客户相关资料并保存')
          let uploadFiles = this.$refs['refUpload'].uploadFiles
          uploadFiles.forEach((value, index) => {
            if (value.uid === file.uid) {
              uploadFiles.splice(index, 1)
              this.file_img_list.splice(index, 1)
            }
          })
          return false
        }
      })
    },
    // 上传之前的回调
    beforeUpload(file) {
      const fileType = file.type
      const isJPG =
        fileType === 'image/jpg' || fileType === 'image/jpeg' || fileType === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传图片的格式只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传图片的大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 图片的删除方法
    handleRemove(file) {
      // 注意图片删除时，会改变图片的Url格式，但是不影响具体的使用。
      // 通过删除成功后，再进行对应的移除
      this.$api({
        method: 'post',
        url: '/admin/upload/deleteImg',
        data: {
          url: file.url,
          uid: file.uid
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success('图片删除成功！')
          // 再查一遍
          this.editRowEvent(this.dataForm.id)
        } else {
          this.$message.error('图片删除失败！')
        }
      })
    },
    // 大图预览
    itemImgPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 下载  imgage图片blob类型下载
    handleDownload(file) {
      let a = document.createElement('a')
      a.download = file.name
      a.href = file.url
      a.click()
    },
    editRowEvent(id) {
      this.dataForm.id = id
      this.showModal = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/stock/editRow',
        params: {
          id: id
        }
      }).then((res) => {
        if (res.status === 200) {
          this.dataForm.img_list = res.data.stock.img_list
          if (this.dataForm.img_list != null && this.dataForm.img_list != []) {
            this.file_img_list = JSON.parse(this.dataForm.img_list) // 将json字符串转换成json对象
          } else {
            this.file_img_list = []
          }
        }
      })
    }
  }
}
</script>
