<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <el-form :model="formData" label-width="80px" @submit.native.prevent>
      <vxe-toolbar ref="refToolbar" size="mini" import export print custom>
        <template #buttons>
          <el-button type="danger" size="mini" @click="removeBatchEvent">删除供应商 </el-button>
        </template>
        <template #tools>
          <el-select
            class="m-r-10"
            size="mini"
            placeholder="请选择"
            filterable
            clearable
            v-model="filterCategory"
            @change="categorySelect"
          >
            <el-option
              v-for="item in filterCategoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
          <el-button type="primary" size="mini" @click="searchListData">搜索</el-button>
          <el-button type="primary" size="mini" @click="dialogImprotVisible_juan_cai = true">
            卷材搜索
          </el-button>
        </template>
      </vxe-toolbar>
    </el-form>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      show-overflow
      highlight-hover-row
      highlight-current-row
      show-footer
      :max-height="this.$util.getViewHeight() - 148"
      :cell-class-name="cellClassName"
      :import-config="{
        remote: true,
        importMethod: this.importMethod,
        modes: ['insert'],
        type: ['xlsx'],
        types: ['xlsx']
      }"
      :checkbox-config="{ range: true }"
      :print-config="{}"
      :export-config="{
        type: ['xlsx'],
        types: ['xlsx', 'csv', 'html', 'xml', 'txt'],
        isFooter: true,
        isColgroup: true
      }"
      :data="tableDataMain"
      :columns="tableColumn"
      :footer-method="footerMethod"
      :mouse-config="{ selected: true }"
      :edit-config="{
        showIcon: false,
        trigger: 'dblclick',
        mode: 'cell'
      }"
      :keyboard-config="{
        enterToTab: false,
        isArrow: true,
        isDel: false,
        isEnter: false,
        isTab: true,
        isEdit: true
      }"
      @cell-click="cellCheckboxRowEventGrid"
      @keydown="onGridKeydown"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_img_list="{ row }">
        <!-- 图片显示 -->
        <div v-if="!$util.isBlankVue(row.img_list)">
          <div v-for="(imgs, index) in JSON.parse(row.img_list)" :key="index">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="itemImgPreview(imgs.url)">
                <img
                  style="height: 30px"
                  class="el-upload-list__item-thumbnail"
                  :src="'/img' + imgs.url"
                />
              </span>
            </span>
          </div>
        </div>
        <vxe-modal
          v-model="dialogVisible"
          width="500px"
          title="资料图片"
          :esc-closable="false"
          :mask-closable="true"
          :lock-view="true"
          :mask="false"
          resize
          :draggable="true"
        >
          <img width="100%" :src="'/img' + dialogImageUrl" alt="" />
        </vxe-modal>
      </template>
      <template #default_gcvip_stock_edit="{ row, column }">
        <el-input
          type="number"
          size="mini"
          v-model.number="row[column.field]"
          @change="gcvipStockCell(row, column)"
          clearable
        >
        </el-input>
      </template>
      <template #default_operate="{ row }">
        <template>
          <vxe-button
            icon="el-icon-upload"
            title="上次"
            circle
            @click="$refs.refPopupStock.editRowEvent(row.id)"
          />
        </template>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <vxe-modal
      width="1220px"
      :title="!isShowDialog ? '失败' : '导入数据'"
      v-model="dialogImprotVisible"
      :esc-closable="true"
      :lock-view="false"
      :mask="false"
      resize
    >
      <el-tag type="danger" style="margin-bottom: 5px">只会更新：“最低限价”、“成本价格”</el-tag>
      <vxe-grid
        size="mini"
        height="400"
        border
        show-overflow
        :columns="tableImport"
        :data="tableDataImport"
        :edit-config="{ trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o' }"
      >
      </vxe-grid>
      <div align="center" style="margin-top: 10px">
        <template v-if="isShowDialog">
          <el-button @click="dialogImprotVisible = false">取消</el-button>
        </template>
        <template v-else>
          <el-button @click="dialogImprotVisible = false">导入失败</el-button>
        </template>
        <el-button type="primary" @click="saveImport" v-show="isShowDialog">确定</el-button>
      </div>
    </vxe-modal>

    <vxe-modal
      width="420px"
      title="卷材查询"
      v-model="dialogImprotVisible_juan_cai"
      :esc-closable="true"
      :lock-view="false"
      :mask="false"
      resize
    >
      <div class="m-y-10">
        <el-form label-width="100px">
          <el-form-item label="厚度范围">
            <el-input
              size="mini"
              type="number"
              placeholder="请输入名称"
              :style="{ width: '134px' }"
              v-model="filterData.coiled_material.thickness_list[0]"
              @blur="$util.inputFocus('coiled_material_thickness_list2')"
            />
            至
            <el-input
              ref="coiled_material_thickness_list2"
              size="mini"
              type="number"
              placeholder="请输入名称"
              :style="{ width: '134px' }"
              v-model="filterData.coiled_material.thickness_list[1]"
              @blur="$util.inputFocus('coiled_material_side_width')"
            />
          </el-form-item>
          <el-form-item label="宽度">
            <el-input
              ref="coiled_material_side_width"
              size="mini"
              type="number"
              placeholder="请输入名称"
              v-model="filterData.coiled_material.side_width"
              @blur="$util.inputFocus('coiled_material_single_weight')"
            />
          </el-form-item>
          <el-form-item label="重量">
            <el-input
              ref="coiled_material_single_weight"
              size="mini"
              type="number"
              placeholder="请输入名称"
              v-model="filterData.coiled_material.single_weight"
            />
          </el-form-item>
          <el-form-item label=" ">
            <el-button type="primary" size="mini" @click="juanSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </vxe-modal>

    <vxe-modal
      width="520px"
      title="卷材明細"
      v-model="dialogImprotVisible_juan_cai_detail"
      :esc-closable="true"
      :lock-view="false"
      :mask="false"
      resize
    >
      <div align="center" style="margin-top: 10px">
        <el-tag>规格：{{ row_detail.specification }}</el-tag>
        <br />
        <el-tag type="success">切割-卷数：{{ row_detail.spilt_count }}</el-tag>
        <br />
        <el-tag type="info">切割-余量：{{ row_detail.spilt_remainder }}</el-tag>
        <br />
        <el-tag type="warning">切割-废料重量：{{ row_detail.spilt_waste }}</el-tag>
        <br />
        <el-tag type="danger">切割-每卷重量：{{ row_detail.spilt_weight }}</el-tag>
        <br />
        <el-tag type="danger">切割-每mm重量：{{ row_detail.spilt_weight_every_mm }}</el-tag>
        <br />
        <el-tag type="danger">切割-总重量：{{ row_detail.spilt_weight_total }}</el-tag>
      </div>
    </vxe-modal>
    <PopupStock ref="refPopupStock"></PopupStock>
  </div>
</template>

<script>
import PopupStock from './popup-stock'
export default {
  components: { PopupStock },
  data() {
    return {
      goBackRow: false,
      filterCategory: '',
      filterCategoryOptions: [],
      row_detail: {
        specification: '',
        spilt_count: '',
        spilt_remainder: '',
        spilt_waste: '',
        spilt_weight: '',
        spilt_weight_every_mm: '',
        spilt_weight_total: ''
      },
      dialogImprotVisible_juan_cai: false,
      dialogImprotVisible_juan_cai_detail: false,
      isShowDialog: false,
      dialogImprotVisible: false,
      tableDataImport: [],
      tableImport: [
        {
          field: 'id',
          width: 50,
          title: 'id'
        },
        {
          field: 'place',
          title: '产地',
          width: 100
        },
        {
          field: 'remark',
          width: 80,
          title: '备注'
        },
        {
          field: 'category',
          width: 100,
          title: '类别'
        },
        {
          field: 'specification',
          width: 150,
          title: '规格'
        },
        {
          field: 'outside',
          visible: false,
          width: 80,
          title: '外径(mm)'
        },
        {
          field: 'side_length',
          visible: false,
          width: 80,
          title: '边长(mm)'
        },
        {
          field: 'side_width',
          visible: false,
          width: 80,
          title: '边宽(mm)'
        },
        {
          field: 'thickness',
          visible: false,
          width: 80,
          title: '厚度(mm)'
        },
        {
          field: 'length_extent',
          visible: false,
          width: 80,
          title: '长度(m)'
        },
        {
          field: 'texture',
          title: '材质',
          width: 80
        },

        {
          field: 'craft',
          title: '品类',
          width: 100
        },
        {
          field: 'shape',
          title: '形状',
          width: 80
        },
        {
          field: 'surface',
          title: '表面',
          width: 80
        },
        {
          field: 'single_weight',
          width: 120,
          title: '支重、卷重(kg)'
        },
        {
          field: 'steel_seal',
          title: '钢印',
          width: 80
        },
        {
          field: 'factory_bag',
          title: '厂袋',
          width: 80
        },
        {
          field: 'steel_level',
          title: '等级',
          width: 80
        },
        {
          field: 'tons_of_price',
          width: 100,
          title: '单价(元/KG)'
        },
        {
          field: 'single_price',
          width: 100,
          title: '单价(元/支)'
        },
        {
          field: 'single_count',
          width: 100,
          title: '支数、卷数'
        },
        {
          field: 'weighing_heavy',
          width: 100,
          title: '库存重量'
        },
        {
          fixed: 'right',
          field: 'error',
          title: '系统提示',
          width: 200,
          slots: {
            default: ({ row }) => {
              return [<el-link type="danger">{row.error}</el-link>]
            }
          }
        }
      ],
      // 图片start
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      remvoeIds: [],
      // 图片end
      formData: {},
      tableDataMain: [],
      filterData: {
        shape: '',
        category: '',
        coiled_material: {
          single_weight: 2000,
          side_width: 300,
          thickness_list: [0.1, 1]
        }
      },
      sortData: { id: 'desc' },
      headerFilterUrl: '/admin/gcvip/stock/searchHeard',
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          field: 'id',
          title: '序号',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'place',
          title: '产地',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'category',
          width: 60,
          title: '类别',
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'specification',
          width: 100,
          title: '规格',
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'texture',
          title: '材质',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'craft',
          title: '品类',
          width: 100,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'shape',
          title: '形状',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'surface',
          title: '表面',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'outside',
          width: 80,
          title: '外径(mm)',
          visible: false,
          className: 'unEditor'
        },
        {
          field: 'side_length',
          width: 80,
          title: '边长(mm)',
          visible: false,
          className: 'unEditor'
        },
        {
          field: 'side_width',
          width: 80,
          title: '边宽(mm)',
          visible: false,
          className: 'unEditor'
        },
        {
          field: 'thickness',
          width: 80,
          title: '厚度(mm)',
          visible: false,
          className: 'unEditor'
        },
        {
          field: 'thickness_true',
          width: 80,
          title: '实厚(mm)',
          visible: false,
          className: 'unEditor'
        },
        {
          field: 'length_extent',
          width: 80,
          title: '长度(m)',
          visible: false,
          className: 'unEditor'
        },
        {
          field: 'single_weight',
          width: 120,
          title: '支重、卷重(kg)',
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'steel_seal',
          title: '钢印',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'factory_bag',
          title: '厂袋',
          width: 60,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'hv',
          title: '硬度',
          width: 80,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'steel_level',
          title: '等级',
          width: 80,
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'remark',
          title: '备注',
          className: 'unEditor',
          slots: { header: 'header_autocomplete' }
        },
        {
          fixed: 'right',
          field: 'tons_of_price',
          width: 80,
          title: '单价(元/吨)',
          slots: { header: 'header_autocomplete' }
        },
        {
          fixed: 'right',
          field: 'kg_of_price',
          width: 80,
          title: '单价(元/KG)',
          editRender: { autofocus: 'input.el-input__inner' },
          slots: {
            header: 'header_autocomplete',
            edit: 'default_gcvip_stock_edit'
          }
        },
        {
          fixed: 'right',
          field: 'single_price',
          width: 80,
          title: '单价(元/支)',
          editRender: { autofocus: 'input.el-input__inner' },
          slots: {
            header: 'header_autocomplete',
            edit: 'default_gcvip_stock_edit'
          }
        },
        {
          fixed: 'right',
          field: 'single_count',
          width: 80,
          title: '支数、卷数',
          editRender: { autofocus: 'input.el-input__inner' },
          slots: {
            header: 'header_autocomplete',
            edit: 'default_gcvip_stock_edit'
          }
        },
        {
          fixed: 'right',
          field: 'weighing_heavy',
          width: 80,
          title: '库存重量',
          editRender: { autofocus: 'input.el-input__inner' },
          slots: {
            header: 'header_autocomplete',
            edit: 'default_gcvip_stock_edit'
          }
        },
        {
          fixed: 'right',
          field: 'base_price',
          width: 80,
          title: '基价',
          editRender: { autofocus: 'input.el-input__inner' },
          slots: {
            header: 'header_autocomplete',
            edit: 'default_gcvip_stock_edit'
          }
        },
        {
          fixed: 'right',
          field: 'add_price',
          width: 80,
          title: '加价(元/kg)',
          editRender: { autofocus: 'input.el-input__inner' },
          slots: {
            header: 'header_autocomplete',
            edit: 'default_gcvip_stock_edit'
          }
        },
        {
          fixed: 'right',
          field: 'img_list',
          title: '材质书',
          width: 60,
          slots: {
            header: 'header_autocomplete',
            default: 'default_img_list'
          }
        },
        {
          fixed: 'right',
          title: '材质书',
          width: 60,
          slots: { default: 'default_operate' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      }
    }
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    categorySelect() {
      this.filterData.category = this.filterCategory
      this.searchListData()
    },
    categorySetting(query = '') {
      this.$api({
        method: 'post',
        url: '/admin/categorySetting',
        params: {
          keyword: query
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.filterCategoryOptions = res.data.page.list
        }
      })
    },

    cellCheckboxRowEventGrid({ row, column }) {
      if (column.field == 'id') {
        this.row_detail = row
        this.dialogImprotVisible_juan_cai_detail = true
      }
    },
    importMethod({ file }) {
      const formBody = new FormData()
      formBody.append('file', file)
      this.$api({
        method: 'post',
        url: '/admin/gcvip/stock/importExcel',
        data: formBody
      })
        .then((res) => {
          if (res.status === 200 && res.data.state === 'ok') {
            if ('加价' == res.data.type) {
              this.$message.success(res.data.msg)
            } else {
              this.tableDataImport = res.data.list
              this.isShowDialog = res.data.isOkSave
              this.uuid = res.data.uuid
              this.dialogImprotVisible = true
            }
          } else {
            this.$message.error(res.data.msg)
            return
          }
        })
        .catch(() => {
          this.$message.error('网络超时，请重试！')
        })
    },
    saveImport() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/stock/saveImportExcel',
        params: {
          uuid: this.uuid
        }
      })
        .then((res) => {
          if (res.status === 200 && res.data.state === 'ok') {
            this.$message.success('导入成功')
            this.dialogImprotVisible = false
          } else {
            this.$message.error(res.data.msg)
            return
          }
          this.searchListData()
        })
        .catch(() => {
          this.$message.error('网络超时，请重试！')
        })
    },
    // 图片start
    itemImgPreview(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    },
    // 每个单元格编辑的时候，提交cell的值
    gcvipStockCell(row, column) {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/stock/gcvipStockCell',
        data: {
          cellValue: row[column.field],
          cellTitle: column.field,
          id: row.id
        }
      }).then((res) => {
        if (res.data.state == 'fail') {
          this.$message.error(res.data.msg)
        } else if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success('操作成功')
          this.searchListData()
        }
      })
    },
    cellClassName({ column }) {
      if (
        column.field === 'kg_of_price' ||
        column.field === 'single_price' ||
        column.field === 'single_count' ||
        column.field === 'weighing_heavy'
      ) {
        return 'col-fail'
      }
    },
    removeBatchEvent() {
      const $grid = this.$refs.refTable
      const selectRecords = $grid.getCheckboxRecords()
      this.remvoeIds = []
      for (let i = 0; i < selectRecords.length; i++) {
        this.remvoeIds.push(selectRecords[i].id)
      }
      if (selectRecords.length > 0) {
        this.$XModal.confirm('您确定要删除 ' + this.remvoeIds.length + ' 条数据?').then((type) => {
          if (type === 'confirm') {
            // 传送删除动作
            this.$api({
              method: 'post',
              url: '/admin/gcvip/stock/deleteBatch',
              data: {
                remvoeIds: this.remvoeIds
              }
            }).then((res) => {
              if (res.status === 200 && res.data.state === 'ok') {
                this.$message.success('批量删除成功')
                this.getListData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请先选择')
      }
    },
    // 钢卷查询
    juanSearch() {
      this.filterData.shape = '卷材'
      this.searchListData()
      this.dialogImprotVisible_juan_cai = false
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.tableDataMain = []
      this.$api({
        method: 'post',
        url: '/admin/gcvip/stock/list',
        data: {
          type: this.type,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData,
          formData: this.formData
        }
      }).then((res) => {
        if (res.data.state == 'fail') {
          this.$message.error(res.data.msg)
          return
        } else if (res.status === 200) {
          this.tableDataMain = res.data.page.list
          this.pageOptions.pageSize = res.data.page.pageSize
          this.pageOptions.total = res.data.page.totalRow
          this.pageOptions.currentPage = res.data.page.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    footerMethod({ columns, data }) {
      const sums = []
      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          sums.push('合计')
        } else {
          if (
            column.field === 'before_count' ||
            column.field === 'before_stock_amount' ||
            column.field === 'before_weighing_heavy_total'
          ) {
            sums.push(this.sumNum(data, column.field))
          } else {
            sums.push('')
          }
        }
      })
      // 返回一个二维数组的表尾合计
      return [sums]
    },
    // 如果存在grid rowid 可能导致row._X_ROW_KEY 失灵从而无法跳转
    onGridKeydown(event) {
      const data = event.$grid.getData() // 获取数据
      const { row, rowIndex, $rowIndex, columnIndex, $columnIndex, column } =
        event.$grid.getSelectedCell() || event.$grid.getEditRecord() || {} // 根据激活的行或者所选中的单元cell获取当前列ID、行ID、行 event.$grid.getSelectedCell()  ||
      let columnIndexMy = columnIndex
      if (!this.$util.isBlankVue($columnIndex)) {
        columnIndexMy = $columnIndex
      }
      let rowIndexMy = rowIndex
      if (!this.$util.isBlankVue($rowIndex)) {
        rowIndexMy = $rowIndex
      }
      const columns = event.$grid.getColumns() // 获取当前列
      if (row && row._X_ROW_KEY && columnIndexMy >= 0) {
        // gridVoucher 凭证表
        if (
          event.$grid.$vnode.data.ref == 'gridVoucher' &&
          event.$event.code == 'Space' &&
          (column.field == 'borrow_money' || column.field == 'lent_money')
        ) {
          let numBorrowMoney = row.borrow_money
          let numLentMoney = row.lent_money
          row.borrow_money = numLentMoney
          row.lent_money = numBorrowMoney
          return
        } else if (
          event.$event.code == 'Enter' ||
          event.$event.code == 'NumpadEnter' ||
          event.$event.code == 'ArrowRight'
        ) {
          // 判断下拉框是否存在，如果存在则不进行操作，否则直接继续执行回车下一个单元格
          if (event.$event.code == 'ArrowRight' && event.$grid.isEditByRow(row)) {
            return
          }
          this.onGridKeydownNext(
            row._X_ROW_KEY,
            columnIndexMy,
            columns,
            rowIndexMy,
            data,
            event,
            'Enter',
            $rowIndex
          )
        } else if (event.$event.code == 'ArrowLeft') {
          if (event.$grid.isEditByRow(row)) {
            return
          }
          this.onGridKeydownNext(
            row._X_ROW_KEY,
            columnIndexMy,
            columns,
            rowIndexMy,
            data,
            event,
            'left',
            $rowIndex
          )
        } else if (event.$event.code == 'ArrowUp') {
          if (columns[columnIndexMy].field == 'polish_roll') {
            if (event.$grid.isEditByRow(row)) {
              return
            }
          }
          if (
            (this.formData.bill_id_type == 'KD' &&
              columns[columnIndexMy].field == 'single_count') ||
            columns[columnIndexMy].field == 'summary_cash_data' ||
            columns[columnIndexMy].field == 'subject_id_cash_data' ||
            columns[columnIndexMy].field == 'subject_id_and_name'
          ) {
            if (event.$grid.isEditByRow(row)) {
              return
            }
          }
          this.onGridKeydownNext(
            row._X_ROW_KEY,
            columnIndexMy,
            columns,
            rowIndexMy,
            data,
            event,
            'up',
            $rowIndex
          )
        } else if (event.$event.code == 'ArrowDown') {
          if (columns[columnIndexMy].field == 'polish_roll') {
            if (event.$grid.isEditByRow(row)) {
              return
            }
          }
          if (
            (this.formData.bill_id_type == 'KD' &&
              columns[columnIndexMy].field == 'single_count') ||
            columns[columnIndexMy].field == 'specification' ||
            columns[columnIndexMy].field == 'summary_cash_data' ||
            columns[columnIndexMy].field == 'subject_id_cash_data' ||
            columns[columnIndexMy].field == 'subject_id_and_name'
          ) {
            if (event.$grid.isEditByRow(row)) {
              return
            }
          }
          this.onGridKeydownNext(
            row._X_ROW_KEY,
            columnIndexMy,
            columns,
            rowIndexMy,
            data,
            event,
            'down',
            $rowIndex
          )
        } else if (event.$grid.$vnode.data.ref == 'gridVoucher') {
          // 激活当前输入框，用于凭证金额输入页面，切换手动判断并激活编辑状态
          event.$grid.setEditCell(row, columns[columnIndexMy].field)
        }
      }
    },
    /**
     * xGrid 控制表格键盘方向键
     * @param rowId
     * @param columnIndex
     * @param columns
     * @param rowIndex
     * @param data
     * @param event
     * @param keyCode
     */
    onGridKeydownNext(rowId, columnIndex, columns, rowIndex, data, event, keyCode, $rowIndex) {
      let nextColumn
      let currentRow = event.$grid.getRowById(rowId)
      if (keyCode == 'Enter') {
        nextColumn = columns.find((item, idx) => {
          return (
            idx > columnIndex &&
            !!item.editRender &&
            item.className != 'unEditor' &&
            item.title.indexOf('费') == -1
          )
        })
      } else if (keyCode == 'left') {
        nextColumn = null
        for (let i = 0; i < columns.length; i++) {
          if (i < columnIndex && !!columns[i].editRender) {
            nextColumn = columns[i]
          }
        }
      } else if (keyCode == 'up' || keyCode == 'down') {
        if (keyCode == 'up' && rowIndex > 0) {
          currentRow = event.$grid.getRowById(data[rowIndex - 1]._X_ROW_KEY)
          nextColumn = columns[columnIndex]
        } else if (keyCode == 'down' && rowIndex < data.length - 1) {
          currentRow = event.$grid.getRowById(data[rowIndex + 1]._X_ROW_KEY)
          nextColumn = columns[columnIndex]
        }
      }
      if (currentRow) {
        // 跳转下一行
        if (nextColumn) {
          if (this.goBackRow) {
            event.$grid.setEditCell(currentRow, nextColumn)
            this.goBackRow = false
            return
          }
          // 还有一个bug，不知道为什么数量太多的情况下，setSelectCell失效，只能用setEditCell
          if (
            nextColumn.field == 'initial_balance' ||
            nextColumn.field == 'accumulated_debits_current_year' ||
            nextColumn.field == 'cumulative_credit_current_year'
          ) {
            event.$grid.setEditCell(currentRow, nextColumn)
            // 如果是字段则不进行选中，直接激活编辑状态 nextColumn.field != 'remark_stock' &&
          } else if (
            nextColumn.field != 'cash_data_remark' &&
            nextColumn.field != 'company_name' &&
            nextColumn.field != 'draw_company' &&
            nextColumn.field != 'remark' &&
            nextColumn.field != 'borrow_money' &&
            nextColumn.field != 'lent_money'
          ) {
            event.$grid.setSelectCell(currentRow, nextColumn)
          } else {
            event.$grid.setEditCell(currentRow, nextColumn)
          }
        } else if (rowIndex > 0 && keyCode == 'left') {
          this.onGridKeydownNext(
            data[rowIndex - 1]._X_ROW_KEY,
            columns.length - 1,
            columns,
            rowIndex,
            data,
            event,
            'left',
            $rowIndex
          )
          this.goBackRow = true
        } else if (rowIndex < data.length - 1 && keyCode == 'Enter') {
          this.onGridKeydownNext(
            data[rowIndex + 1]._X_ROW_KEY,
            0,
            columns,
            rowIndex,
            data,
            event,
            'Enter',
            $rowIndex
          )
        }
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.categorySetting()
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
      this.getListData()
    })
  }
}
</script>

<style lang="less" scoped>
.page-main {
  /deep/ .unEditor {
    background-color: #f8f8f8;
  }
}
</style>
